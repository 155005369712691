import * as React from 'react';
import { Cell } from '../components/Cell';

import { Layout } from '../components/Layout';
import { Logo } from '../components/Logo';
import { Row } from '../components/Row';
import { SEO } from '../components/SEO';

const NotFoundPage = () => {
  return (
    <Layout>
      <Row className="bg-gradient-to-b from-gray-800 to-gray-900 text-white">
        <Cell>
          <div className="flex flex-col items-center justify-center h-screen">
            <h1 className="text-5xl font-bold mb-4">Page Not Found</h1>
            <p className="text-xl text-gray-200 mb-4">
              Uh oh! We couldn't find the page you were looking for.{' '}
            </p>
            <p>
              <a
                href="/"
                className="text-primary underline hover:text-white transition-colors duration-150 ease-in-out"
              >
                Head back home &rarr;
              </a>
            </p>
            <a
              className="absolute bottom-10 left-1/2 -translate-x-1/2"
              href="/"
            >
              <Logo />
            </a>
          </div>
        </Cell>
      </Row>
    </Layout>
  );
};

export function Head() {
  return (
    <SEO>
      <title id="title">EASE // Page Not Found</title>
    </SEO>
  );
}

export default NotFoundPage;
